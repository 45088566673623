import React from "react";
import "../HowItWork/how.css";
import "../Animline/anim.css";
import { FaApple } from "react-icons/fa";
import { DiAndroid } from "react-icons/di";

const How = () => {
  return (
    // <!-- How-It-Workes-Section-Start -->
    <section className="row_am how_it_works" id="how_it_work">
      {/* <!-- container start --> */}
      <div className="container">
        <div className="how_it_inner">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="300"
          >
            {/* <!-- h2 --> */}
            <h2>
              <span>Download the TripHoppa App</span> in 3 easy steps It's Free!
            </h2>
            {/* <!-- p --> */}
            <p>
              Getting started with TripHoppa is quick and easy. Follow these
              three simple steps <br /> to join a community of smart travelers
              and start saving time and money on your rides.
            </p>
          </div>
          <div className="step_block">
            {/* <!-- UL --> */}
            <ul>
              {/* <!-- step --> */}
              <li>
                <div
                  className="step_text"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <h4>Download the app</h4>
                  <div className="app_icon">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.triphoppa.encounter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DiAndroid size={24} />
                    </a>
                    <a
                      href="https://apps.apple.com/app/triphoppa/id6736841581"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaApple size={24} />
                    </a>
                  </div>
                  <p>Available for iOS and Android</p>
                </div>
                <div className="step_number">
                  <h3>01</h3>
                </div>
                <div
                  className="step_img"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <img src="/enc/images/download_app.jpg" alt="img" />
                </div>
              </li>

              {/* <!-- step --> */}
              <li>
                <div
                  className="step_text"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <h4>Create account</h4>
                  <p>Sign up for Free and Personalize your experience.</p>
                </div>
                <div className="step_number">
                  <h3>02</h3>
                </div>
                <div
                  className="step_img"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <img src="/enc/images/create_account.jpg" alt="img" />
                </div>
              </li>

              {/* <!-- step --> */}
              <li>
                <div
                  className="step_text"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <h4>It’s done, enjoy the app</h4>
                  <p>
                    Get the most amazing app experience, Explore and share the
                    app
                  </p>
                </div>
                <div className="step_number">
                  <h3>03</h3>
                </div>
                <div
                  className="step_img"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <img src="/enc/images/enjoy_app.jpg" alt="img" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- container end --> */}
    </section>
  );
};

export default How;
