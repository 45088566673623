import React, { useEffect } from "react";
import "../Hero/hero.css";
import Animline from "../Animline/Animline";
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className="banner_section" id="home">
      <div className="container">
        <Animline />
        <div className="row">
          <div className="col-lg-6 col-md-12" data-aos-duration="1500">
            <div className="banner_text">
              {/* <!-- h1 --> */}

              <h1>
                Share Rides. Deliver Parcels. <span>Maximize Every Mile.</span>
              </h1>
              {/* <!-- p --> */}
              <p>
                Find Rides and Deliveries, powered by the community. Join
                TripHoppa for easy Carpooling and Fast, Reliable Shipment
                Delivery.
              </p>
            </div>
            <div className="waitlist_btn">
              <button>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeq4wAtgjjZc0PkK8qBm5MqFsKLKpkCa2N4oPlozcGYoRBpUQ/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-aos="fade-in"
                  data-aos-duration="1500"
                >
                  Book Now
                </a>
              </button>
            </div>
          </div>

          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <div className="banner_slider d-flex justify-content-center align-items-center">
              <div className="left_icon">
                <img src="/enc/images/message_icon.png" alt="img" />
              </div>
              <div className="right_icon">
                <img src="/enc/images/shield_icon.png" alt="img" />
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/enc/images/mockups/1-1.png"
                  className="w-[25rem]"
                  alt="img"
                  style={{ width: 25 + "rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
